import React, { useEffect, useState } from 'react';
import SelectsContainer from './SelectsContainer';

const SlideMultiSelects = ({ slide, setPoints, points, changeState, setRight }) => {

  const [userAnswers, setUserAnswers] = useState([]);

  const correction = JSON.parse(slide.round_correction);

  const changeUserAnswers = (index, value) => {
    // Créer une copie du tableau
    let newArray = [...userAnswers];

    // Modifier la deuxième valeur dans la copie
    newArray[parseInt(index)] = parseInt(value);

    // Mettre à jour l'état avec la nouvelle copie du tableau
    setUserAnswers(newArray);
  }

  const checkAnswers = () => {
    let ready = true;
    userAnswers.forEach((answer) => {
      if (answer < 0) {
        console.log("test")
        ready = false;
      }
    });

    return ready;
  }

  const handleValidate = () => {
    if (userAnswers.length >= JSON.parse(slide.round_correction).length && checkAnswers()) {

      if (JSON.stringify(correction) === JSON.stringify(userAnswers)) {
        setPoints(points + 20);
        console.log("bon");
        changeState();
      }
      else {
        setRight(false);
        changeState();
      }
    }
  }

  useEffect(() => {
    let newArray = [...userAnswers];
    for (let i = 0; i < JSON.parse(slide?.round_answers).length; i++) {
      newArray[i] = -1;
    }
    setUserAnswers(newArray);
  }, [])


  return (
    <>
      <article className={"tplt-" + slide.template}>
        <div className="question" dangerouslySetInnerHTML={{ __html: slide.question }}>

        </div>

        <ul className='all-answers'>
          {
            JSON.parse(slide?.round_answers).map((answer, index) => <li key={index}><strong>{answer}</strong></li>)
          }
        </ul>

        <ul className='selects-list'>
          {JSON.parse(slide?.round_labels).map((label, index) =>
            <SelectsContainer key={index} label={label} answers={JSON.parse(slide?.round_answers)} changeUserAnswers={changeUserAnswers} selectIndex={index} />
          )
          }
        </ul>
      </article>
      <button onClick={handleValidate}>
        Valider
      </button>
    </>
  );
};

export default SlideMultiSelects;