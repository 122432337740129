import React, { useEffect, useState } from 'react';
import SlideClassicQuestion from './SlideClassicQuestion';
import SlideRoundQuestion from './SlideRoundQuestion';
import SlideText from './SlideText';
import SlideRoundNumbers from './SlideRoundNumbers';
import Emoji from './Emoji';
import SlideMultiSelects from './SlideMultiSelects';
import axios from 'axios';
import AnimationHalo from './AnimationHalo';

const TemplateSelector = ({ slide, setPoints, points, changeState, setRight, right }) => {
  switch (slide.template) {
    case 'inline-text':
      return <SlideText slide={slide} changeState={changeState} right={right} />;
    case 'classic-question':
      return <SlideClassicQuestion slide={slide} setPoints={setPoints} points={points} changeState={changeState} setRight={setRight} />;
    case 'round-question':
      return <SlideRoundQuestion slide={slide} setPoints={setPoints} points={points} changeState={changeState} setRight={setRight} />;
    case 'round-numbers':
      return <SlideRoundNumbers slide={slide} setPoints={setPoints} points={points} changeState={changeState} setRight={setRight} />;
    case 'multi-selects':
      return <SlideMultiSelects slide={slide} setPoints={setPoints} points={points} changeState={changeState} setRight={setRight} />;
    default:
      return null; // ou un composant par défaut si nécessaire
  }
};

const GameSlides = ({ slides, setPoints, points, changeGlobalState, setSlideName, user, defi, nbFails, setNbFails }) => {

  const [state, setState] = useState(0);
  const [right, setRight] = useState(true);
  const [roleBackQuestion, setRoleBackQuestion] = useState(-1);

  const changeState = () => {
    if (slides[state + 1]) {
      if (!slides[state].template.includes("-text") && roleBackQuestion === -1) {
        setRoleBackQuestion(state);
      }
      else if (slides[state].template.includes("-text") && slides[state].correction === "1") {
        setRoleBackQuestion(-1);
        setRight(true);
      }
      setState(state + 1);
    }
    else {
      changeGlobalState();
    }

    if (document.body.classList.contains("lessons-bg")) {
      document.body.classList.remove("lessons-bg");
    }
    if (document.body.classList.contains("files-bg")) {
      document.body.classList.remove("files-bg");
    }
    console.log(points)
  }

  const previousState = () => {

    axios.post('https://connivence-salon.net/api/e-learning/game/post/fails.php', new URLSearchParams({
      user: user.id, defi
    })).then(response => {
      setState(roleBackQuestion);
      setRoleBackQuestion(-1);
      setNbFails(nbFails + 1);
    }).catch(error => {
      // Gérer l'erreur ici, par exemple en affichant un message d'erreur
      console.error('Erreur lors de l\'envoi de la requête:', error);
      // Peut-être définir un état pour afficher l'erreur dans le popup
    });
  }

  const getEmoji = (slide) => {
    if (slide.template === "inline-text") {
      if (slide.correction === "1") {
        if (right) {
          return "success";
        }
        else {
          return "fail";
        }
      }
      else {
        return "lesson";
      }
    }
    else {
      return "question";
    }
  }

  useEffect(() => {
    setSlideName(slides[state].name);
  }, [state])


  return (
    <section className={slides[state].lesson === "0" ? "game-slides" : "game-slides lesson-slide"}>
      <Emoji src={getEmoji(slides[state])} />
      {slides[state] && <TemplateSelector slide={slides[state]} setPoints={setPoints} points={points} changeState={changeState} setRight={setRight} right={right} />}
      {(slides[state].template.includes("-text") && slides[state].lesson === "0" && slides[state].correction === "0") || (slides[state].template.includes("-text") && slides[state].correction === "1" && right === true) ?
        <button className='next-slide' onClick={() => {
          setRight(true);
          changeState();
        }}>Suivant</button>
        :
        ""}
      {
        (slides[state].template.includes("-text") && slides[state].correction === "1" && right === false) ?

          <button className='next-slide' onClick={() => {
            setRight(true);
            previousState();
          }}>Réessayer</button>
          :
          ""
      }

      {slides[state].lesson === "0" ?
        <AnimationHalo slide={slides[state]} />
        :
        ""
      }
    </section>
  );
};

export default GameSlides;